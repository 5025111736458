import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import UTextarea from "components/common/element/UTextarea";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import StringUtils from "components/common/utils/StringUtils";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Form, FormGroup } from "react-bootstrap";
import CodeService from "services/common/CodeService";

function NewServicePopup({ callbackFnc, ...props }) {
  const [serviceInfo, setServiceInfo] = useState({
    description: "",
    serviceId: "",
    serviceName: "",
    serviceType: "S",
    isTransactionIsolated: false,
    isIgnoreException: false,
  });
  const [serviceTypeOptions, setserviceTypeOptions] = useState([]);

  useEffect(() => {
    getOptions();
  }, []);

  const getOptions = () => {
    CodeService.getCodeCombo({ codeMstCd: "Z0040" }, (res) => {
      setserviceTypeOptions(res.data);
    });
  };

  const onChangeProperty = (e) => {
    setServiceInfo({
      ...serviceInfo,
      [e.target.id]: e.target.value,
    });
  };

  const onConfirmNewService = (e) => {
    stopEvent(e);
    if (StringUtils.isEmpty(serviceInfo.serviceId)) {
      return Message.alert("Please enter Service ID.", Enums.MessageType.INFO);
    }
    if (StringUtils.isEmpty(serviceInfo.serviceName)) {
      return Message.alert(
        "Please enter Service Name.",
        Enums.MessageType.INFO
      );
    }
    if (StringUtils.isEmpty(serviceInfo.serviceType)) {
      return Message.alert(
        "Please enter Service Type.",
        Enums.MessageType.INFO
      );
    }

    if (callbackFnc) {
      callbackFnc(serviceInfo);
    }
  };
  return (
    <Modal>
      <Modal.Header title="Create New Service" />
      <Modal.Body>
        <UModalTemplate>
          <FormGroup>
            <Form.Label className="required">Service ID</Form.Label>
            <Form.Control
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceId"}
              value={serviceInfo.serviceId}
            />
            <Form.Label className="required">Service Name</Form.Label>
            <Form.Control
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceName"}
              value={serviceInfo.serviceName}
              placeholder="Input Service name"
            />
            <Form.Label className="required">Service Type</Form.Label>
            <Form.Select
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceType"}
              value={serviceInfo.serviceType}
            >
              <option value="">Choose</option>
              {serviceTypeOptions.map((option) => {
                return (
                  <option key={option.id} value={option.id}>
                    {option.text}
                  </option>
                );
              })}
            </Form.Select>
            <Form.Label className="required">Transaction Mode</Form.Label>
            <span className="mb-3">
              <Form.Check
                type="radio"
                name="isTransactionIsolated"
                label={
                  "Shared Transaction (Commit deferred until overall process completion)"
                }
                value={false}
                checked={serviceInfo.isTransactionIsolated === false}
                onChange={(e) => {
                  setServiceInfo({
                    ...serviceInfo,
                    isTransactionIsolated: false,
                  });
                }}
              />
              <Form.Check
                type="radio"
                name="isTransactionIsolated"
                label={
                  "Isolated Transaction (Auto-commit immediately after service execution)"
                }
                value={true}
                checked={serviceInfo.isTransactionIsolated === true}
                onChange={(e) => {
                  setServiceInfo({
                    ...serviceInfo,
                    isTransactionIsolated: true,
                  });
                }}
              />
            </span>

            {serviceInfo.isTransactionIsolated && (
              <>
                <Form.Label className="required">Exception Handling</Form.Label>
                <span className="mb-3">
                  <Form.Check
                    type="radio"
                    name="isIgnoreException"
                    label={
                      "On exception, rollback previous transaction and stop the service."
                    }
                    value={false}
                    checked={serviceInfo.isIgnoreException === false}
                    onChange={(e) => {
                      setServiceInfo({
                        ...serviceInfo,
                        isIgnoreException: false,
                      });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    name="isIgnoreException"
                    label={"Ignore exceptions and continue service execution"}
                    value={true}
                    checked={serviceInfo.isIgnoreException === true}
                    onChange={(e) => {
                      setServiceInfo({
                        ...serviceInfo,
                        isIgnoreException: true,
                      });
                    }}
                  />
                </span>
              </>
            )}

            <Form.Label classNAme="mt-3">Service Description</Form.Label>
            <UTextarea
              popTitle="Service Description"
              textareaId="description"
              onChange={onChangeProperty}
              defaultValue={serviceInfo.description}
              codeBox={false}
              rows={10}
            />
          </FormGroup>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.Button onClick={onConfirmNewService}>
          Confirm
        </Modal.Footer.Button>
      </Modal.Footer>
    </Modal>
  );
}

export default NewServicePopup;
