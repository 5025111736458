import { Accordion, Card, Col, Form, Row } from "react-bootstrap";
import SunEditor from "suneditor-react";
import { styled } from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import ObjectUtils from "components/common/utils/ObjectUtils";
import ArrayUtils from "components/common/utils/ArrayUtils";
import produce from "immer";
import { setDesign } from "../reducer/ProgramDesignAction";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { TextField } from "@mui/material";
import CommonUtils, { StringUtils } from "components/common/utils/CommonUtils";
import UInputPopup from "components/common/element/UInputPopup";
import Popup from "components/common/Popup";
import ProgramListPopup from "page/popup/ProgramListPopup";
import { debounce } from "components/common/utils/InputUtils";
import User from "components/common/utils/UserUtils";

export const ScrollDiv = styled.div`
  padding-left: 100px;
  padding-right: 100px;
  max-height: calc(100vh - 200px);
  overflow-x: hidden;
  overflow-y: auto;
  &::-webkit-scrollbar {
    width: 4px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 2px;
    background: #ccc;
  }
`;

const ProgramDesignOverview = ({ designMst, setDesignMst, flag }) => {
  const workspace = useSelector((state) => state.workspace);

  const [designMultilang, setDesignMultilang] = useState([]);
  const [editorContent, setEditorContent] = useState();
  // const [appEnvList, setAppEnvList] = useState([]);
  const editor = useRef();
  const technicalDesign = useSelector((state) => state.technicalDesign);

  const dispatch = useDispatch();

  const priorityOptions = ["A", "B", "C"];
  const difficultyOptions = ["H", "M", "L"];
  const frequencyOptions = [
    "Anytime",
    "Daily",
    "Weekly",
    "Monthly",
    "BIWeekly",
  ];

  useEffect(() => {
    if (editorContent !== undefined) {
      onChangeDesignMultilang("remarks", editorContent);
    }
  }, [editorContent]);

  useEffect(() => {
    if (flag) {
      setDesignMst({});
      setDesignMultilang([]);
    }
  }, [flag]);

  useEffect(() => {
    setDesignMultilang(designMst.designMultilang || []);
  }, [designMst]);

  useEffect(() => {
    setDesignMst(technicalDesign.design);
  }, [technicalDesign.design.designMstId]);

  /**
   * multilang text 가져오기
   * @param {*} key
   * @returns
   */
  const getMultilangText = (key) => {
    if (ArrayUtils.isArray(designMultilang)) {
      const findMultilang = designMultilang.filter(
        (item) =>
          item.multilangCd === key &&
          item.langCd === User.getLanguage().toLowerCase()
      );
      if (!ArrayUtils.isEmpty(findMultilang)) {
        return findMultilang[0].multilangText;
      } else {
        return "";
      }
    }
  };

  /**
   * redux 패치
   */
  const mstDebounce = debounce((newDesignMst) => {
    dispatch(setDesign(newDesignMst));
  }, 200);

  /**
   * designMst 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMst = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      const newDesignMst = produce(designMst, (draft) => {
        draft[key] = value;
      });
      setDesignMst(newDesignMst);
      mstDebounce(newDesignMst);
    }
  };

  /**
   * designMultilang 변경
   * @param {*} key
   * @param {*} value
   */
  const onChangeDesignMultilang = (key, value) => {
    if (ObjectUtils.isObject(designMst)) {
      let newDesignMultilang = [...designMultilang];

      const findIndex = designMultilang.findIndex(
        (item) =>
          item.multilangCd === key &&
          item.langCd === User.getLanguage().toLowerCase()
      );

      if (findIndex > -1) {
        newDesignMultilang = produce(designMultilang, (draft) => {
          draft[findIndex].multilangText = value;
        });
      } else {
        const newData = {
          multilangCd: key,
          multilangText: value,
          langCd: User.getLanguage().toLowerCase(),
        };
        newDesignMultilang = produce(designMultilang, (draft) => {
          draft.push(newData);
        });
      }
      onChangeDesignMst("designMultilang", newDesignMultilang);
      setDesignMultilang(newDesignMultilang);
    }
  };

  /**
   * editor blur
   * @param {*} e
   */
  const onBlurEditor = (e) => {
    setEditorContent(editor.current.getContents());
  };

  /**
   * program list popup open
   */
  const openProgramListPopup = () => {
    const options = {
      style: {
        content: {
          width: "50%", //popup의 크기를 50% (default 60%)
          minWidth: "1000px",
        },
      },
    };
    Popup.open(
      <ProgramListPopup
        workspace={workspace}
        title="Program List popup"
        callbackFnc={(data) => {
          const newDesignMst = produce(designMst, (draft) => {
            draft.appId = data.appId;
            draft.appReleaseId = data.appReleaseId;
            draft.coCd = data.coCd;
            draft.programId = data.programId;
            //draft.moduleNm = data.moduleNm;
            draft.moduleCd = data.moduleCd;
            draft.tenantId = data.tenantId;
          });

          setDesignMst(newDesignMst);
          dispatch(setDesign(newDesignMst));
          Popup.close();
        }}
      />,
      options
    );
  };

  const getSunEditorInstance = (sunEditor) => {
    editor.current = sunEditor;
  };

  return (
    <ScrollDiv>
      <Accordion defaultActiveKey={[1, 2, 3]} alwaysOpen>
        <Accordion.Item eventKey={1}>
          <Accordion.Header
            style={{
              backgroundColor: "rgb(237 242 250)",
            }}
            textCenter
          >
            <div style={{ marginLeft: "auto" }}>
              Project Specification Overview
            </div>
          </Accordion.Header>
          <Accordion.Body>
            <Row className="mt-3">
              <Col xs={2}>
                <Form.Label>Project Name</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Control value={workspace?.appNm || ""} disabled />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={2}>
                <Form.Label>Module</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Control value={workspace?.moduleNm || ""} disabled />
              </Col>
              <Col xs={2}>
                <Form.Label>Sub Module</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Control
                  value={getMultilangText("sub_module_nm")}
                  onChange={(e) =>
                    onChangeDesignMultilang("sub_module_nm", e.target.value)
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={2}>
                <Form.Label className="required">Program ID</Form.Label>
              </Col>
              <Col xs={4}>
                <UInputPopup
                  id="dprogramId"
                  defaultValue={designMst?.programId || ""}
                  value={designMst?.programId || ""}
                  onClick={openProgramListPopup}
                  readonlyTp={false}
                  onChange={(e) => {
                    if (StringUtils.isEmpty(designMst.appId)) {
                      const newDesignMst = produce(designMst, (draft) => {
                        draft.appId = workspace.appId;
                        draft.appReleaseId = workspace.appReleaseId;
                        draft.coCd = workspace.coCd;
                        draft.moduleCd = workspace.moduleCd;
                        draft.programId = e.target.value;
                        draft.tenantId = workspace.tenantId;
                        draft.appEnvId = workspace.appEnv
                          ? workspace.appEnv.appEnvId
                          : 1;
                      });
                      setDesignMst(newDesignMst);
                      dispatch(setDesign(newDesignMst));
                    } else {
                      onChangeDesignMst("programId", e.target.value);
                    }
                  }}
                />
              </Col>
              <Col xs={2}>
                <Form.Label>Process ID</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Control
                  value={designMst?.processId || ""}
                  onChange={(e) =>
                    onChangeDesignMst("processId", e.target.value)
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={2}>
                <Form.Label className="required">Program Name</Form.Label>
              </Col>
              <Col xs={10}>
                <Form.Control
                  value={getMultilangText("program_nm")}
                  onChange={(e) =>
                    onChangeDesignMultilang("program_nm", e.target.value)
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={2}>
                <Form.Label>Program Overview</Form.Label>
              </Col>
              <Col xs={10}>
                <Form.Control
                  as="textarea"
                  value={getMultilangText("program_desc")}
                  onChange={(e) =>
                    onChangeDesignMultilang("program_desc", e.target.value)
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={2}>
                <Form.Label>Requester</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Control value={designMst?.requesterId} disabled />
              </Col>
              <Col xs={2}>
                <Form.Label>Request Date</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Control
                  disabled
                  value={CommonUtils.getDate(designMst?.requestDate) || ""}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={2}>
                <Form.Label>Expected Development Period</Form.Label>
              </Col>
              <Col xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={designMst?.estimatedDate}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    onChange={(value) =>
                      onChangeDesignMst("estimatedDate", value.$d)
                    }
                  />
                </LocalizationProvider>
              </Col>
              <Col xs={2}>
                <Form.Label>Desired Completion Date</Form.Label>
              </Col>
              <Col xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={designMst?.expectedDate}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    onChange={(value) =>
                      onChangeDesignMst("expectedDate", value.$d)
                    }
                  />
                </LocalizationProvider>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={2}>
                <Form.Label>Developer</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Control
                  value={designMst?.developer || ""}
                  onChange={(e) =>
                    onChangeDesignMst("developer", e.target.value)
                  }
                />
              </Col>
              <Col xs={2}>
                <Form.Label>Development Completion Date</Form.Label>
              </Col>
              <Col xs={4}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    value={designMst?.completionDate}
                    renderInput={(params) => (
                      <TextField {...params} size="small" />
                    )}
                    onChange={(value) =>
                      onChangeDesignMst("completionDate", value.$d)
                    }
                  />
                </LocalizationProvider>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={2}>
                <Form.Label>Priority(A/B/C)</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Select
                  value={designMst?.priority || ""}
                  onChange={(e) =>
                    onChangeDesignMst("priority", e.target.value)
                  }
                >
                  {priorityOptions.map((priority) => {
                    return (
                      <option key={priority} value={priority}>
                        {priority}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
              <Col xs={2}>
                <Form.Label>Difficulty level(H/M/L)</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Select
                  value={designMst?.difficultyLevel || ""}
                  onChange={(e) =>
                    onChangeDesignMst("difficultyLevel", e.target.value)
                  }
                >
                  {difficultyOptions.map((difficulty) => {
                    return (
                      <option key={difficulty} value={difficulty}>
                        {difficulty}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={2}>
                <Form.Label>Program Type</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Control
                  value={designMst?.dataHandlingType || ""}
                  onChange={(e) =>
                    onChangeDesignMst("dataHandlingType", e.target.value)
                  }
                />
              </Col>
              <Col xs={2}>
                <Form.Label>Reuse PGM-ID</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Control
                  value={designMst?.refProgramId || ""}
                  onChange={(e) =>
                    onChangeDesignMst("refProgramId", e.target.value)
                  }
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col xs={2}>
                <Form.Label>Frequency Of Performance</Form.Label>
              </Col>
              <Col xs={4}>
                <Form.Select
                  value={designMst?.execFrequency || ""}
                  onChange={(e) =>
                    onChangeDesignMst("execFrequency", e.target.value)
                  }
                >
                  {frequencyOptions.map((frequency) => {
                    return (
                      <option key={frequency} value={frequency}>
                        {frequency}
                      </option>
                    );
                  })}
                </Form.Select>
              </Col>
            </Row>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>

      <Row className="mt-3">
        <Col>
          <Card style={{ backgroundColor: "rgb(237 242 250)" }}>
            <Card.Body>
              <Card.Text style={{ textAlign: "center" }}>
                <Form.Label>Other characteristics</Form.Label>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <SunEditor
            lang={"ko"}
            onBlur={onBlurEditor}
            setContents={getMultilangText("remarks")}
            getSunEditorInstance={getSunEditorInstance}
            setDefaultStyle="font-family: 'Noto Sans KR', sans-serif;  min-height : 500px; max-height : 55vh"
            setOptions={{
              font: [
                "Noto Sans KR",
                "Arial",
                "Comic Sans MS",
                "Courier New",
                "Impact",
                "Georgia",
                "tahoma",
                "Trebuchet MS",
                "Verdana",
              ],
              fontSize: [
                8, 9, 10, 11, 12, 13, 14, 16, 18, 20, 22, 24, 26, 28, 36, 48,
                72,
              ],
              buttonList: [
                ["font", "fontSize", "formatBlock"],
                [
                  "bold",
                  "underline",
                  "italic",
                  "strike",
                  "fontColor",
                  "hiliteColor",
                ],
              ],
            }}
          />
        </Col>
      </Row>
    </ScrollDiv>
  );
};
export default ProgramDesignOverview;
