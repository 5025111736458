import { Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import WorkflowReduxHelper from "components/builder/workflow/editor/helper/WorkflowReduxHelper";
import {
  addBreakpoint,
  deleteBreakpoint,
} from "components/builder/workflow/reducer/WorkflowDebugAction";
import Message from "components/common/Message";
import CommonUtils, {
  ArrayUtils,
  JsonUtils,
  ObjectUtils,
  StringUtils,
} from "components/common/utils/CommonUtils";
import { WorkflowContext } from "page/workflow";
import React, { memo, useContext, useEffect, useRef, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { MdCheck, MdOutlineCancel } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Handle, NodeResizer, Position, useEdges, useNodes } from "reactflow";
import {
  findAndChange,
  getEntityPropertyValue,
  getNodeBetweenList,
} from "../editor/render/WorkflowRenderUtils";
import { BundleNode } from "./BundleNode";
import { CodeNode } from "./CodeNode";
import { ConditionNode } from "./ConditionNode";
import { ConnectorNode } from "./ConnectorNode";
import { IteratorNode } from "./IteratorNode";
import { ProcessEdge } from "./ProcessEdge";
import ProcessNode from "./ProcessNode";
import { ServiceNode } from "./ServiceNode";
import produce from "immer";

/**
 * WorkflowBuilder에서 사용하는 각 유형별 노드의 형태를 지정하는 파일입니다.
 * 프로세스 노드 : ProcessNodeType
 * 서비스 노드 : ServiceNodeType
 * 커넥터 : ConnectorNodeType
 * 코드 : CodeNodeType
 * 이터레이터 : IteratorNodeType
 * 그룹핑 : BundleNodeType
 *
 */

/**
 * 메모 타입
 */
export const MemoNodeType = memo(({ data, id, selected }) => {
  const [isEditMode, setIsEditMode] = useState(false);
  const descriptionRef = useRef();
  const titleRef = useRef();
  const dispatch = useDispatch();
  const workflow = useSelector((state) => state.workflow);
  const [titleEditMode, setTitleEditMode] = useState(false);
  const [description, setDescription] = useState(data?.memo?.description || "");

  /**
   * 내용 수정
   * @param {*} e
   */
  const onBlurDescription = (e) => {
    const description = descriptionRef.current.value;
    const newMemo = [{ ...data.memo, description }];
    WorkflowReduxHelper.updateMemo(dispatch, newMemo, workflow);
    setIsEditMode(false);
  };

  useEffect(() => {
    if (isEditMode && descriptionRef.current) {
      descriptionRef.current.focus();
      setDescription(data.memo.description || "");
    }

    const handleClickOutside = (event) => {
      // descriptionRef의 요소 안에서 클릭했는지 여부를 확인
      if (
        descriptionRef.current &&
        !descriptionRef.current.contains(event.target)
      ) {
        // 바깥을 클릭했을 때 필요한 동작
        onBlurDescription();
      }
    };

    if (isEditMode) {
      // window에 click 이벤트 리스너 추가
      window.addEventListener("click", handleClickOutside);
    } else {
      window.removeEventListener("click", handleClickOutside);
    }

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, [isEditMode]);

  /**
   * 메모 삭제
   */
  const onDeleteMemo = () => {
    WorkflowReduxHelper.deleteMemo(dispatch, id, workflow);
  };

  /**
   * 메모 타이틀 수정
   * @param {*} e
   */
  const onBlurTitle = (e) => {
    const title = titleRef.current.value;
    setTitleEditMode(false);
    const newMemo = [{ ...data.memo, title }];
    WorkflowReduxHelper.updateMemo(dispatch, newMemo, workflow);
  };

  return (
    <>
      <NodeResizer
        color="dodgeblue"
        isVisible={selected}
        minWidth={200}
        minHeight={150}
        handleStyle={{
          width: "12px",
          height: "12px",
          borderRadius: "100%",
          zIndex: 1,
        }}
      />
      <div className="workflow-memo nowheel ">
        <div className="header">
          {titleEditMode ? (
            <>
              <div className="title">
                <Form.Control
                  defaultValue={data.memo.title}
                  ref={titleRef}
                  onBlur={onBlurTitle}
                />
              </div>
              <div className="button-area">
                <button onClick={onBlurTitle}>
                  <MdCheck color="lime" />
                </button>
                <button onClick={(e) => setTitleEditMode(false)}>
                  <MdOutlineCancel color="tomato" />
                </button>
              </div>
            </>
          ) : (
            <>
              <div className="title">{data.memo.title}</div>
              <div className="button-area">
                <button onClick={(e) => setTitleEditMode(true)}>
                  <AiOutlineEdit color="lime" />
                </button>
                <button onClick={onDeleteMemo}>
                  <AiOutlineClose />
                </button>
              </div>
            </>
          )}
        </div>
        <div className="description-wrapper">
          {isEditMode ? (
            <>
              <textarea
                className="description nodrag"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                ref={descriptionRef}
                onClick={stopEvent}
                onScroll={stopEvent}
                onScrollCapture={stopEvent}
              />
              <div className="button-area">
                <Button size="sm" variant="outline-success">
                  submit
                </Button>
                <Button
                  size="sm"
                  variant="outline-danger"
                  onClick={(e) => {
                    stopEvent(e);
                    setIsEditMode(false);
                  }}
                >
                  cancel
                </Button>
              </div>
            </>
          ) : (
            <div
              className="description"
              onClick={stopEvent}
              onDoubleClick={(e) => setIsEditMode(true)}
            >
              {data.memo.description}
            </div>
          )}
        </div>
      </div>
    </>
  );
});

/**
 * Node 생성시 공통 함수들 만들어 두는곳
 * HOC 방식으로 제작
 * @param {*} param0
 * @returns
 */
const withNodeCommonMethod = ({ NodeElement }) => {
  /**
   * 플로우 선 연결 하는 핸들러 부착하는 함수
   * @returns
   */
  const addHandler = () => {
    return (
      <span className="handle-wrapper">
        <Handle
          type="source"
          className="targetHandle"
          position={Position.Top}
          id="a"
        />
        <Handle
          type="source"
          className="targetHandle"
          position={Position.Left}
          id="b"
        />
        <Handle
          type="source"
          className="targetHandle"
          position={Position.Right}
          id="c"
        />

        <Handle
          type="source"
          className="targetHandle"
          position={Position.Bottom}
          id="d"
        />
      </span>
    );
  };

  /**
   * 참조중인 노드 아이디 목록 찾기
   * @param {*} object
   * @returns
   */
  const findReferCompId = (object) => {
    const idList = [];

    const _find = (_obj) => {
      for (const key in _obj) {
        if (typeof _obj[key] === "object") {
          _find(_obj[key]);
        } else if (key === "referenceCompId") {
          if (_obj[key]) {
            idList.push(_obj[key]);
          }
        }
      }
    };
    _find(object);
    return idList;
  };

  const WrappedComponent = (propertys) => {
    const {
      data: { process, comment, isBundling },
      id,
      selected,
      ...props
    } = propertys;
    const {
      bundle: {
        bundlingMode,
        bundleNodeList,
        setBundleNodeList,
        bundleStartNode,
        bundleEndNode,
        setBundleStartNode,
        setBundleEndNode,
      },
    } = useContext(WorkflowContext);
    const workflow = useSelector((state) => state.workflow);
    const dispatch = useDispatch();
    const {
      breakpoint: breakpoints,
      trace,
      isDebugging,
      process: debugProcess,
      breakpointType,
      inCommunication,
    } = useSelector((state) => state.workflowDebug);
    const [isTracing, setIsTracing] = useState(false); // 디버깅 중 트레이싱 되는지
    const [isBreakPoint, setIsBreakPoint] = useState(false); //브레이크 포인트에 걸린 노드인지
    const [isInvalid, setIsInvalid] = useState(false);
    const [variableInvalid, setVariableInvalid] = useState(false); //variable이 없을때 invalid 체크
    const [isChild, parentNodeId, parentNode] = useIsIteratorChild(id);
    const workspace = useSelector((state) => state.workspace);
    const nodes = useNodes();
    const edges = useEdges();

    useEffect(() => {
      if (process) {
        /**
         * 참조중인 노드가 삭제되거나 compID를 찾을 수 없는 경우 isInvalid를 활성화 한다.
         */
        const referenceCompIdList = findReferCompId(process);
        if (referenceCompIdList.length > 0) {
          for (const compId of referenceCompIdList) {
            const refNode = JsonUtils.findNode(
              workflow.output.service,
              "compId",
              compId
            );
            if (ObjectUtils.isEmpty(refNode)) {
              setIsInvalid(true);
              break;
            } else {
              setIsInvalid(false);
            }
          }
        } else {
          setIsInvalid(false);
        }

        //사용중인 variable이 없을때 reference Comp와 다를때 variableInvalid 를 true로 한다.
      }
    }, [process]);

    useEffect(() => {
      if (!isDebugging) {
        setIsTracing(false);
        setIsBreakPoint(false);
      } else {
        if (isDebugging && ArrayUtils.isArray(trace)) {
          const _isTracing = trace.find((t) => t.compId === id);
          if (_isTracing) setIsTracing(true);
          else setIsTracing(false);
        }
        if (isDebugging && !ObjectUtils.isEmpty(debugProcess)) {
          if (debugProcess.compId === id) {
            setIsBreakPoint(true);
          } else {
            setIsBreakPoint(false);
          }
        }
      }
    }, [isDebugging, trace, debugProcess]);

    /**
     * 번들 예정 리스트에 번들 추가
     * @param {*} node
     */
    const onAddBundle = (e, node) => {
      stopEvent(e);

      /**
       * 0. 번들 목록에 아무것도 없는 경우는 그냥 startnode로 등록한다.
       * 1. 특정 노드를 선택 할시 해당 노드를 번들 그룹 예정 목록에 추가한다.
       * 2. 연결된 노드가 아닐수 있기 때문에 기존에 번들 노드목록에서 최단 기간에 있는 선을 찾는다.
       * 3. 선택한 노드와 연결되는 과정의 모든 노드를 추가한다.
       */
      // 1. 캔버스 위 노드들 중 선택된 노드를 target으로 하는 connector 검색
      const sourceNode = nodes.find((n) => n.id === node.compId);
      //노드 선택 이벤트로 그룹핑에 추가하려고 했으나, 같은 버튼 사용 방식으로 변경
      if (ObjectUtils.isEmpty(bundleNodeList)) {
        // 번들 노드 목록이 정의 되지 않으면 시작 노드 , 종료 노드 설정을 우선으로 한다.
        if (ObjectUtils.isEmpty(bundleStartNode)) {
          setBundleStartNode(sourceNode);
        } else if (
          !ObjectUtils.isEmpty(bundleStartNode) &&
          ObjectUtils.isEmpty(bundleEndNode)
        ) {
          setBundleEndNode(sourceNode);
          const betweenList = getNodeBetweenList(
            bundleStartNode,
            sourceNode,
            nodes
          );
          if (
            !ArrayUtils.isArray(betweenList) ||
            ArrayUtils.isEmpty(betweenList)
          )
            return Message.alert(
              "Unable to find intermediate node connections or the node group is included.",
              Enums.MessageType.WARN
            );
          const nodeIdMap = {};
          betweenList.forEach((node) => {
            nodeIdMap[node.id] = true;
          });
          setBundleNodeList(nodeIdMap);
        }
      } else {
        const sourceConnectors = nodes.filter(
          (n) =>
            n.type === Enums.WorkflowNodeType.CONNECTOR &&
            n.data.connector.processTo === sourceNode.id
        );

        const checkedList = {};
        let shortcutProcessMap = {};
        /**
         * 번들 노드에 있는 노드에 도착하는 지를 판단
         * 도착한 노드를 From 또는 To로 해서 getNodeBetweenList를 실행한다.
         * @param {*} cons
         * @param {*} direction
         */
        const findShortcut = (cons, direction) => {
          const theOtherSide =
            direction === "processFrom" ? "processTo" : "processFrom";
          cons.forEach((con) => {
            checkedList[con.id] = true;
            const nextProcess = nodes.find(
              (d) => con.data.connector[direction] === d.id
            );
            if (nextProcess && !checkedList[nextProcess.id]) {
              //커넥터 다음 프로세스가 묶음에 있는 경우는 해당 프로세스를 기준으로 사이 프로세스 목록을 가져온다.
              if (bundleNodeList[nextProcess.id]) {
                shortcutProcessMap[direction] = nextProcess;
              } else {
                checkedList[nextProcess.id] = true;
                const nextCons = nodes.filter(
                  (n) =>
                    n.type === Enums.WorkflowNodeType.CONNECTOR &&
                    n.data.connector[theOtherSide] === nextProcess.id
                );
                findShortcut(nextCons, direction);
              }
            }
          });
        };
        /**
         * 선택한 노드가 그룹핑 노드보다 뒤에 있는 경우
         */
        findShortcut(sourceConnectors, "processFrom");
        /**
         * 선택한 노드가 그룹핑 노드보다 앞에 있는 경우
         */
        findShortcut(sourceConnectors, "processTo");

        let betweenNodeList = {};
        //위의 결과를 모두 받아서 betweenNodeList 변수에 [compId] : true 형태로 저장한다.
        Object.keys(shortcutProcessMap).forEach((key) => {
          let betweenList = [];
          if (key === "processFrom") {
            betweenList = getNodeBetweenList(
              shortcutProcessMap[key],
              sourceNode,
              nodes
            );
          } else {
            betweenList = getNodeBetweenList(
              sourceNode,
              shortcutProcessMap[key],
              nodes
            );
          }
          betweenList.forEach((n) => {
            betweenNodeList[n.id] = true;
          });
        });
        //번들 노드 추가
        setBundleNodeList({
          ...bundleNodeList,
          ...betweenNodeList,
          [node.compId]: true,
        });
      }
    };

    //노드 그룹 목록에서 대상 노드를 삭제하는 로직
    const onDeleteFromBundle = (e, node) => {
      stopEvent(e);
      /**
       * 1. node를 구한다.
       *  1-1. node를 번들 묶음에서 삭제한다.
       * 2. node로 부터 processTo로 하는 커넥터를 구한다.
       *  2-1. 번들 묶음에서 커넥터정보가 있으면 삭제 한다.
       *  2-2. 커넥터 정보가 없으면 멈춘다. 그 뒤로는 더이상 없을 예정이기 때문
       * 3. 2-1에서 processFrom -> To로 이어지는 프로세스를 구한다
       * 4. 반복
       */

      const sourceNode = nodes.find((n) => n.id === node.compId);

      const bundleListObj = { ...bundleNodeList };

      //선택한 노드 삭제
      delete bundleListObj[sourceNode.id];
      //선택한 노드를 바라보는 커넥터 뎁스 1 -> 삭제
      const sourceConnectors = nodes.filter(
        (n) =>
          n.type === Enums.WorkflowNodeType.CONNECTOR &&
          n.data.connector.processTo === sourceNode.id
      );
      sourceConnectors.forEach((con) => {
        if (bundleListObj[con.id]) {
          delete bundleListObj[con.id];
        }
      });

      const findAndDelete = (sNode) => {
        // 선택한 노드로 부터 뻗어나가는 커넥터를 재귀적으로 확인하여 이미 선택한 노드 목록에서 제함
        const sCon = nodes.filter(
          (n) =>
            n.type === Enums.WorkflowNodeType.CONNECTOR &&
            n.data.connector.processFrom === sNode.id
        );

        sCon.forEach((con) => {
          if (bundleListObj[con.id]) {
            delete bundleListObj[con.id];
            //이어지는 프로세스 구함
            const nextProcess = nodes.find(
              (n) => n.id === con.data.connector.processTo
            );
            if (nextProcess) {
              if (bundleListObj[nextProcess.id]) {
                delete bundleListObj[nextProcess.id];
                //다음 커넥터 구하기 -> 재귀
                findAndDelete(nextProcess);
              }
            }
          }
        });
      };

      // findAndDelete(sourceNode, "processFrom");
      findAndDelete(sourceNode, "processTo");

      //바뀐 번들 노드 적용
      setBundleNodeList(bundleListObj);
    };

    //브레이크 포인트 설정하는 로직
    const setBreakpoint = (e, { type, compId, processName, processType }) => {
      stopEvent(e);
      dispatch(
        addBreakpoint({
          compId,
          type,
          processName,
          processType,
          serviceUid: workflow.serviceInfo.serviceUid,
          serviceId: workflow.serviceInfo.serviceId,
          serviceName: workflow.serviceInfo.serviceName,
        })
      );
    };

    /**
     * 브레이크 포인트 삭제
     * @param {*} e
     */
    const removeBreakpoint = (e, { compId }) => {
      stopEvent(e);
      dispatch(
        deleteBreakpoint({
          compId,
        })
      );
    };
    /**
     * 주석 체크 하는 로직
     * 주석 여부만 리턴한다.
     * @returns {Boolean}
     */
    const commentCheckMethod = () => {
      let parentComment = false;
      if (parentNodeId) {
        parentComment = ObjectUtils.isEmpty(
          JsonUtils.findNode(workflow.serviceComment, "compId", parentNodeId)
        )
          ? false
          : true;
      }
      if (comment || parentComment) {
        return true;
      } else {
        return false;
      }
    };

    // 번들 추가 제외 버튼
    const addBundleControlButton = (OtherComponent) => {
      if (bundlingMode) {
        //이터레이터 안의 노드(isChild = true)들은 아무 표시 하지 않는다.
        if (isChild && parentNode.type === Enums.WorkflowNodeType.ITERATOR)
          return <></>;
        else
          return (
            <div className="control-button">
              {isBundling ? (
                <Tooltip title="Exclude from Group" placement="top">
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={(e) => onDeleteFromBundle(e, process)}
                  >
                    Exclude
                  </Button>
                </Tooltip>
              ) : (
                <Tooltip title="Add to Group" placement="top">
                  <Button
                    size="sm"
                    variant="primary"
                    onClick={(e) => onAddBundle(e, process)}
                  >
                    Add
                  </Button>
                </Tooltip>
              )}
            </div>
          );
      } else {
        return OtherComponent || <></>;
      }
    };

    /**
     * Process의 Entity Def가 복사되고 원본이 삭제되었을때
     * refereceCompId 기준으로 본다면 다른 노드 이지만, 동일한 성질(entityVariable)을 가지는 경우에
     * 해당 refereceCompId를 동일한 성질을 가지는 노드로 바꿔줌
     * @param {*} process
     */

    const getAccessibleEntityList = () => {
      const processTypes = [
        Enums.WorkflowProcessType.ENTITY_DEFINITION,
        Enums.WorkflowProcessType.SELECT_ENTITY,
        Enums.WorkflowProcessType.SELECT_ENTITY_BY_QUERY,
        Enums.WorkflowProcessType.SERVICE,
        Enums.WorkflowProcessType.CALL_STORED_PROCEDURE,
        Enums.WorkflowProcessType.UNIERP_CONNECTOR,
        Enums.WorkflowProcessType.REST_API_CONNECTOR,
        Enums.WorkflowProcessType.STRING_TO_JSON,
        Enums.WorkflowProcessType.DATA_AGGREGATION,
        Enums.WorkflowProcessType.GET_MINOR,
      ];
      /**
       * 1. 현재 노드를 찾는다.
       * 2. 현재 노드에서 연결된 커넥터 노드를 찾는다.
       * 3. 2번에서 검색된 노드의 processFrom을 검색한다.
       * 4. 3번에서 검색된 프로세스노드를 nextProcess라고 한다.
       *    4-1 . 검색된 프로세스들을 entityList에 담는다.
       * 5. 2번이 존재하지 않을때 1번의 processType 이 processEdge가 아니면 nextProcess를 Iterator라고 판단한다.
       * 6. Iterator의 자녀를 순회한다
       *    6-1. 1번부터 반복한다.
       */
      const service = workflow.output.service;
      let processList = service.child.process;
      let connectorList = service.child.connector;

      //현재 노드의 부모가 존재하는데, 검색 항목에 Iterator가 없으면 강제로 주입
      if (
        parentNodeId &&
        !processTypes.includes(Enums.WorkflowNodeType.ITERATOR)
      ) {
        processTypes.push(Enums.WorkflowNodeType.ITERATOR);
      }
      // compId
      const compId = id;
      //접근 가능한 entity 목록
      let _entityList = [];
      //확인한 노드 목록. 재귀 조회시 중복방문 방지
      let checkedNodeList = {};

      /**
       * To CompId를 이용해서 From을 찾고, Definition 노드 정보를 가져옴
       * @param {*} to
       */
      const findFromAndDef = (toCompId) => {
        //toCompId로 향하는 connector 목록 검색
        const fromConnectorList = connectorList.filter(
          (con) => con.processTo === toCompId
        );

        if (!ArrayUtils.isEmpty(fromConnectorList)) {
          fromConnectorList.forEach((fCon) => {
            const nextProcess = processList.find(
              (pro) => pro.compId === fCon.processFrom
            );
            const isChecked = checkedNodeList[nextProcess.compId];
            if (!isChecked) {
              checkedNodeList[nextProcess.compId] = true;
              if (
                !_entityList.find((e) => e.compId === nextProcess.compId) &&
                StringUtils.includes(nextProcess.processType, processTypes) &&
                nextProcess.id !== compId
              ) {
                _entityList.push(nextProcess);
              }
              findFromAndDef(nextProcess.compId);
            }
          });
        } else {
          //connector가 없는 경우는 iterator라고 판단한다.
          //iterator의 경우 parentNode를 가지고 있다.
          const parentIteratorNode = JsonUtils.findNode(
            processList,
            "compId",
            toCompId
          );
          if (parentIteratorNode && parentIteratorNode.parentNode) {
            //이터레이터가 중복되어 들어간경우 상위 부모까지 찾는다.
            findFromAndDef(parentIteratorNode.parentNode);
            const _iteratorNode = JsonUtils.findNode(
              processList,
              "compId",
              parentIteratorNode.parentNode
            );
            if (!_entityList.find((e) => e.compId === _iteratorNode.compId)) {
              _entityList.push(_iteratorNode);
            }
          }
        }
      };

      /**
       * 목록에 보여줄 entity 생성
       */

      if (!StringUtils.isEmpty(compId)) {
        findFromAndDef(compId);
      }
      //Def를 정렬 후 , 그외 프로세스에서 DEF 이외의 변수명이 있으면 추가
      let _defEntityList = _entityList.filter(
        (p) =>
          p.processType === Enums.WorkflowProcessType.ENTITY_DEFINITION &&
          p.propertyValue.taskType !== "setEntity"
      );
      let otherList = _entityList.filter(
        (p) => p.processType !== Enums.WorkflowProcessType.ENTITY_DEFINITION
      );
      const defEntityList = [];
      //중복 검사
      for (const def of _defEntityList) {
        if (
          !defEntityList.find(
            (d) =>
              d.propertyValue.entityVariable ===
              def.propertyValue.entityVariable
          )
        ) {
          defEntityList.push(def);
        }
      }
      //def에 없는 항목만 유지
      let enableSelEntityList = otherList.filter(
        (s) =>
          !defEntityList.find((d) => {
            return getEntityPropertyValue(s).find(
              (pv) => pv.entityVariable === d.propertyValue.entityVariable
            );
          })
      );
      const result = [
        ...defEntityList,
        ...enableSelEntityList.filter(
          (f) => !defEntityList.find((e) => e.compId === f.compId)
        ),
      ];
      return result;
    };

    const onClickInvalidButton = () => {
      Message.confirm(
        "해당 프로세스의 참조 변수와 일치하는 Definition Process로, 참조 Process가 갱신됩니다.",
        () => {
          const accesccList = getAccessibleEntityList();
          if (ArrayUtils.isEmpty(accesccList))
            return Message.alert(
              "접근 가능한 프로세스가 없습니다.",
              Enums.MessageType.INFO
            );
          const oldReferenceCompIdList = findReferCompId(process);
          let WF = CommonUtils.deepCopy(workflow);
          oldReferenceCompIdList.forEach((oldReferenceCompId) => {
            const oldRefCompIdObj = JsonUtils.findNode(
              process,
              "referenceCompId",
              oldReferenceCompId
            );
            console.log(oldRefCompIdObj, accesccList);
            const entityVariable = oldRefCompIdObj.entityVariable;
            const newDef = accesccList.find(
              (proc) =>
                proc.processType ===
                  Enums.WorkflowProcessType.ENTITY_DEFINITION &&
                (proc.propertyValue.taskType === "newEntity" ||
                  proc.propertyValue.taskType === "setParameter")
            );

            if (!newDef)
              return Message.alert(
                "접근 가능한 프로세스 중 동일한 변수명을 가진 프로세스가 없습니다.",
                Enums.MessageType.INFO
              );
            if (newDef) {
              WF = produce(WF, (draft) => {
                draft.output = findAndChange(
                  WF.output,
                  { referenceCompId: oldReferenceCompId },
                  {
                    referenceCompId: newDef.compId,
                  }
                );
              });
              WorkflowReduxHelper.updateWorkflow(dispatch, WF, workflow);
            } else {
              Message.alert(
                `Entity 변수명이 ${entityVariable}로 선언된 Definition을 찾을 수 없습니다.`,
                Enums.MessageType.WARN
              );
            }
          });
        }
      );
    };

    return (
      <NodeElement
        {...propertys}
        addHandler={addHandler}
        onAddBundle={onAddBundle}
        onDeleteFromBundle={onDeleteFromBundle}
        setBreakpoint={setBreakpoint}
        removeBreakpoint={removeBreakpoint}
        isTracing={isTracing}
        isBreakPoint={isBreakPoint}
        isInvalid={isInvalid}
        commentCheckMethod={commentCheckMethod}
        inCommunication={inCommunication}
        breakpointType={breakpointType}
        breakpoints={breakpoints}
        isChild={isChild}
        parentNodeId={parentNodeId}
        parentNode={parentNode}
        debugProcess={debugProcess}
        addBundleControlButton={addBundleControlButton}
        onClickInvalidButton={onClickInvalidButton}
        workspace={workspace}
        nodes={nodes}
        edges={edges}
        workflow={workflow}
      />
    );
  };

  return React.memo(WrappedComponent);
};

export const ConnectorNodeType = withNodeCommonMethod({
  NodeElement: ConnectorNode,
});

export const ProcessNodeType = withNodeCommonMethod({
  NodeElement: ProcessNode,
});

export const ProcessEdgeType = withNodeCommonMethod({
  NodeElement: ProcessEdge,
});
export const IteratorNodeType = withNodeCommonMethod({
  NodeElement: IteratorNode,
});

export const ConditionNodeType = withNodeCommonMethod({
  NodeElement: ConditionNode,
});

export const ServiceNodeType = withNodeCommonMethod({
  NodeElement: ServiceNode,
});

export const CodeNodeType = withNodeCommonMethod({
  NodeElement: CodeNode,
});

export const BundleNodeType = withNodeCommonMethod({ NodeElement: BundleNode });

const useIsIteratorChild = (id) => {
  const nodes = useNodes();
  const [parentNode, setParentNode] = useState({});
  const [parentNodeId, setParentNodeId] = useState("");
  const [isChild, setIsChild] = useState(false);

  useEffect(() => {
    const _isChild = getParentNodeId() ? true : false;

    setIsChild(_isChild);
    if (_isChild) {
      setParentNodeId(getParentNodeId());
      setParentNode(getParentNode());
    }
  }, [id, nodes]);

  const getParentNodeId = () => {
    const thisNode = nodes.find((n) => n.id === id);
    return thisNode.parentNode;
  };

  const getParentNode = () => {
    const thisNode = nodes.find((n) => n.id === getParentNodeId());
    if (!ObjectUtils.isEmpty(thisNode)) return thisNode.data.process;
    else return null;
  };
  return [isChild, parentNodeId, parentNode];
};
