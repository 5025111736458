import { memo, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEdges, useNodes } from "reactflow";
import WorkflowReduxHelper from "../editor/helper/WorkflowReduxHelper";
import Popup from "components/common/Popup";
import JsonUtils from "components/common/utils/JsonUtils";
import StringUtils from "components/common/utils/StringUtils";
import ConnectorConditionPopup from "page/popup/workflow/ConnectorConditionPopup";
import { Enums } from "components/builder/BuilderEnum";
import { Connector_popup_width } from "../WorkflowBuilder";
import ConnectorValidationPopup from "page/popup/workflow/ConnectorValidationPopup";
import ConnectorPopup from "page/popup/workflow/ConnectorPopup";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { WorkflowContext } from "page/workflow";

/**
 * 커넥터 타입
 * @param {data:Object,id:String,selected:Boolean}
 */
export const ConnectorNode = memo(
  ({
    data: { connector, comment, isBundling },
    id,
    selected,
    findReferCompId,
    parentNodeId,
    isChild,
    parentNode,
    addHandler,
    onAddBundle,
    onDeleteFromBundle,
    setBreakpoint,
    removeBreakpoint,
    isTracing,
    isBreakPoint,
    isInvalid,
    commentCheckMethod,
    inCommunication,
    breakpointType,
    breakpoints,
    debugProcess,
    workflow,
    workspace,
    nodes,
    edges,
    ...props
  }) => {
    const dispatch = useDispatch();
    const {
      bundle: { bundlingMode },
    } = useContext(WorkflowContext); //번들링 관련

    /**
     * 커넥터 삭제
     */
    const onDeleteConnector = () => {
      WorkflowReduxHelper.deleteConnector(dispatch, id, workflow);
    };

    /**
     * 커넥터 수정
     * PropertyValue가 없는 경우는 반응하지 않음
     * @param {*} e
     */
    const onDoubleClick = (e) => {
      if (!connector.propertyValue) return false;
      const callbackFnc = (connectorInfo) => {
        Popup.close();
        const body = {
          ...connector,
          propertyValue: connectorInfo,
        };
        WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
      };

      const conditionCallbackFnc = (connectorInfo) => {
        Popup.close();
        const body = {
          ...connector,
          propertyValue: connectorInfo,
        };

        const otherConnector = workflow.output.service.child.connector.filter(
          (item) =>
            item.processFrom === connector.processFrom &&
            item.compId !== connector.compId
        )[0];

        const body2 = {
          ...otherConnector,
          propertyValue: {
            filter: !connectorInfo.filter,
            connectorNm: connectorInfo.filter ? "FALSE" : "TRUE",
          },
        };

        // WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
        WorkflowReduxHelper.updateNodes(dispatch, [body2, body], workflow);
      };

      //소스가 Validation 인지 확인
      const sourceNode = JsonUtils.findNode(
        workflow.output,
        "compId",
        connector.processFrom
      );
      const isFromValidation = StringUtils.equalsIgnoreCase(
        sourceNode.processType,
        "EntityValidation"
      );
      if (sourceNode.type === Enums.WorkflowNodeType.CONDITION) {
        Popup.open(
          <ConnectorConditionPopup
            callbackFnc={conditionCallbackFnc}
            workflow={workflow.output}
          />,
          {
            effect: Popup.ScaleUp,
            style: {
              content: {
                width: Connector_popup_width.EntityValidation,
              },
            },
          }
        );
      } else if (isFromValidation) {
        Popup.open(
          <ConnectorValidationPopup
            callbackFnc={callbackFnc}
            workflow={workflow.output}
          />,
          {
            effect: Popup.ScaleUp,
            style: {
              content: {
                width: Connector_popup_width.EntityValidation,
              },
            },
          }
        );
      } else {
        Popup.open(
          <ConnectorPopup
            callbackFnc={callbackFnc}
            connectionData={connector.propertyValue}
            sourceCompId={connector.processFrom}
            targetCompId={connector.processTo}
            workflow={workflow.output}
            nodes={nodes}
            edges={edges}
            compId={connector.processFrom}
            connector={true}
          />,
          {
            effect: Popup.ScaleUp,
            style: {
              content: {
                width: Connector_popup_width.other,
              },
            },
          }
        );
      }
    };

    return (
      <div
        className={`workflow-node ${isChild ? `${parentNodeId}_child` : ""} ${
          comment && ` comment`
        } ${bundlingMode && isBundling ? " bundling" : ""}`}
      >
        {connector.propertyValue.elseYn && (
          <span className="workflow-connector-else">else</span>
        )}
        {connector.propertyValue.connectorNm ? (
          <>
            <div
              className={`workflow-connector-node-wrapper ${
                selected ? "selected" : ""
              } `}
              onDoubleClick={onDoubleClick}
            >
              {comment && (
                <div className="node-comment" onDoubleClick={onDoubleClick}>
                  Comment
                </div>
              )}
              <div>{connector.propertyValue.connectorNm}</div>
              <button onClick={onDeleteConnector}>
                <AiOutlineClose size={20} />
              </button>
            </div>
          </>
        ) : (
          <div
            className={`workflow-connector-node-wrapper no-property ${
              selected ? "selected" : ""
            } `}
            onDoubleClick={onDoubleClick}
          >
            {comment && (
              <div className="node-comment" onDoubleClick={onDoubleClick}>
                Comment
              </div>
            )}
            <button onClick={onDoubleClick}>
              <AiOutlineEdit size={20} color="green" />
            </button>
            <button onClick={onDeleteConnector}>
              <AiOutlineClose size={20} />
            </button>
          </div>
        )}
        {addHandler()}
      </div>
    );
  }
);
