import { Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import { AppContext } from "components/common/AppContextProvider";
import Popup from "components/common/Popup";
import ArrayUtils from "components/common/utils/ArrayUtils";
import StringUtils from "components/common/utils/StringUtils";
import { WorkflowContext } from "page/workflow";
import { lazy, memo, Suspense, useContext, useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { BsFillPinFill, BsPinAngle, BsPuzzle } from "react-icons/bs";
import { MdError } from "react-icons/md";
import { TbArrowFork } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { useEdges, useNodes } from "reactflow";
import WorkflowReduxHelper from "../editor/helper/WorkflowReduxHelper";
import { ModalWidth } from "../WorkflowBuilder";
import { findAndChange } from "../editor/render/WorkflowRenderUtils";
import CommonUtils from "components/common/utils/CommonUtils";

const ProcessNode = memo(
  ({
    data: { process, comment, isBundling, iterator },
    id,
    selected,
    findReferCompId,
    parentNodeId,
    isChild,
    parentNode,
    addHandler,
    onAddBundle,
    onDeleteFromBundle,
    setBreakpoint,
    removeBreakpoint,
    isTracing,
    isBreakPoint,
    isInvalid,
    commentCheckMethod,
    inCommunication,
    breakpointType,
    breakpoints,
    debugProcess,
    addBundleControlButton,
    onClickInvalidButton,
    workflow,
    workspace,
    nodes,
    edges,
    ...props
  }) => {
    const dispatch = useDispatch();
    const {
      bundle: { bundlingMode },
    } = useContext(WorkflowContext); //번들링 관련
    const [isComment, setIsComment] = useState(false);
    const {
      code: { getCodeList },
    } = useContext(AppContext);

    useEffect(() => {
      setIsComment(commentCheckMethod());
    }, [comment]);

    /**
     * 프로세스 수정 로직
     * 브레이크 포인트 설정, 노드 수정 함수에서 사용
     * @param {*} _body
     */
    const updateProcess = (_body) => {
      let WF = CommonUtils.deepCopy(workflow);
      //EntityDefinition을 참조하는 다른 노드에 수정된 정보를 반영함
      if (
        process.processType === Enums.WorkflowProcessType.ENTITY_DEFINITION &&
        _body.propertyValue.entityVariable !==
          process.propertyValue.entityVariable &&
        _body.parentNode === process.parentNode
      ) {
        WF = findAndChange(
          WF,
          { referenceCompId: _body.compId },
          {
            entityNm: _body.propertyValue.entityNm,
            entityVariable: _body.propertyValue.entityVariable,
          }
        );
      }

      if (parentNode.type === Enums.WorkflowNodeType.ITERATOR && isChild) {
        WorkflowReduxHelper.updateIteratorNode(
          dispatch,
          [_body],
          parentNode,
          WF
        );
      } else {
        WorkflowReduxHelper.updateNodes(dispatch, [_body], WF);
      }
    };

    /**
     * 노드 수정
     * @param {*} e
     */
    const onOpenProcessPopup = (e) => {
      const callbackFnc = (callbackData) => {
        const body = {
          ...process,
          propertyValue: callbackData,
        };
        updateProcess(body);

        Popup.close();
      };

      const ProcessDetailPopup = lazy(() =>
        import("page/popup/workflow/process/" + process.processType)
      );

      Popup.open(
        <Suspense fallback={<div></div>}>
          <ProcessDetailPopup
            parentNodeId={parentNodeId}
            workspace={workspace}
            iterator={parentNode}
            // connectionPopupOpen={connectionPopupOpen}
            callbackFnc={callbackFnc}
            operatorList={getCodeList("Z0026")}
            workflow={workflow.output}
            processInfo={process.propertyValue}
            processType={process.processType}
            nodes={nodes}
            edges={edges}
            compId={id}
          />
        </Suspense>,
        {
          style: {
            content: {
              width: ModalWidth[process.processType] || ModalWidth.other,
            },
          },
        }
      );
    };

    /**
     * 프로세스 노드 삭제
     * @param {*} e
     */
    const onDeleteProcess = (e) => {
      stopEvent(e);
      WorkflowReduxHelper.deleteProcess(dispatch, process.compId, workflow);
    };
    /**
     * Node Header 부분
     * @returns
     */
    const renderHeader = () => {
      return (
        <div className="header">
          <div className="title">
            <span>
              {StringUtils.equalsIgnoreCase(
                process.processType,
                Enums.WorkflowProcessType.LOOP_CONTROL_KEYWORD
              ) ? (
                <TbArrowFork size={22} />
              ) : (
                <BsPuzzle size={22} />
              )}
            </span>
            <span
              className="name"
              style={{ maxWidth: isInvalid ? "160px" : "200px" }}
            >
              {process.propertyValue.processNm}
            </span>
          </div>
          {addBundleControlButton(
            <div className="control-button">
              <Tooltip title="Edit" placement="top">
                <button
                  style={{ color: "limegreen" }}
                  onClick={onOpenProcessPopup}
                >
                  <AiOutlineEdit size={20} />
                </button>
              </Tooltip>
              {isInvalid && (
                <Tooltip
                  placement="top"
                  title={
                    <span style={{ fontSize: "12px" }}>
                      Reference Target is not Found <br />
                      <span style={{ fontWeight: "bold" }}>
                        Please reassign the Input Entity or Output Entity.
                        <br />
                      </span>
                    </span>
                  }
                >
                  <button className="blink" onClick={onClickInvalidButton}>
                    <MdError size={25} color="tomato" />
                  </button>
                </Tooltip>
              )}
              <Tooltip title="Break Point" placement="top">
                {breakpoints.find((bp) => bp.compId === process.compId) ? (
                  <button
                    onClick={(e) =>
                      removeBreakpoint(e, { compId: process.compId })
                    }
                  >
                    <BsFillPinFill size={20} />
                  </button>
                ) : (
                  <button
                    onClick={(e) =>
                      setBreakpoint(e, {
                        compId: process.compId,
                        type: Enums.WorkflowNodeType.PROCESS,
                        processName: process.propertyValue.processNm,
                        processType: process.processType,
                      })
                    }
                  >
                    <BsPinAngle size={20} />
                  </button>
                )}
              </Tooltip>
              <button onClick={onDeleteProcess}>
                <AiOutlineClose size={20} />
              </button>
            </div>
          )}
        </div>
      );
    };

    /**
     * Render Body 부분
     */
    const renderBody = () => {
      if (
        StringUtils.equalsIgnoreCase(
          process.processType,
          Enums.WorkflowProcessType.LOOP_CONTROL_KEYWORD
        )
      ) {
        return (
          <div
            className={`workflow-process-node ${Enums.WorkflowProcessType.LOOP_CONTROL_KEYWORD}`}
            onDoubleClick={onOpenProcessPopup}
          >
            <div className="process-type ">
              Loop Control - {process.propertyValue.loopControlKeyword}
            </div>
          </div>
        );
      } else {
        return (
          <div
            className="workflow-process-node"
            onDoubleClick={onOpenProcessPopup}
          >
            <div className="process-type">Process - {process.processType}</div>
            {StringUtils.equalsIgnoreCase(
              process.processType,
              Enums.WorkflowProcessType.ENTITY_DEFINITION
            ) ? (
              <>
                <div>Entity Name - {process.propertyValue.entityNm}</div>
              </>
            ) : (
              <></>
            )}
          </div>
        );
      }
    };

    return (
      <>
        {inCommunication && (
          <>
            {isBreakPoint ? (
              <div className={`debug-info ${breakpointType}`}>
                <div>Before</div>
                <div>in Progress</div>
                <div>After</div>
              </div>
            ) : debugProcess.compId === id ? (
              <div className={`debug-info ${breakpointType}`}>
                <div>Before</div>
                <div className={"blink"}>in Progress</div>
                <div>After</div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}

        <div
          className={`workflow-node 
            ${isChild ? `${parentNodeId}_child` : ""} 
          ${isComment ? ` comment` : ""} 
          ${bundlingMode && isBundling ? " bundling" : ""} 
          `}
        >
          <div
            className={`workflow-process-node-wrapper ${
              selected ? " selected " : ""
            }
             ${process.processType} 
             ${isTracing ? " traced " : ""} 
             ${debugProcess?.compId === id ? " border-blink " : ""} 
             
            `}
          >
            {isComment && (
              <div className="node-comment" onDoubleClick={onOpenProcessPopup}>
                Comment
              </div>
            )}

            {renderHeader()}
            {renderBody()}
          </div>
          {addHandler()}
        </div>
      </>
    );
  }
);

export default ProcessNode;
