import { Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import { stopEvent } from "components/builder/ui/editor/handler/UIEditorEventHandler";
import Popup from "components/common/Popup";
import { memo, useContext, useEffect, useState } from "react";
import { AiOutlineClose, AiOutlineEdit } from "react-icons/ai";
import { BsFillPinFill, BsPinAngle } from "react-icons/bs";
import { MdError, MdMiscellaneousServices } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useEdges, useNodes } from "reactflow";
import WorkflowReduxHelper from "../editor/helper/WorkflowReduxHelper";
import ServicePopup from "page/popup/workflow/ServicePopup";
import Message from "components/common/Message";
import WorkflowService from "services/workflow/WorkflowService";
import produce from "immer";
import JsonUtils from "components/common/utils/JsonUtils";
import SaveQuestPopup from "page/popup/workflow/SaveQuestPopup";
import LocalStorageService from "services/common/LocalService";
import User from "components/common/utils/UserUtils";
import { Button } from "react-bootstrap";
import { WorkflowContext } from "page/workflow";

/**
 * 서비스 노드 타입
 * @param {data:Object,id:String,selected:Boolean}
 */
export const ServiceNode = memo(
  ({
    data: { process, comment, isBundling },
    id,
    selected,
    parentNodeId,
    isChild,
    parentNode,
    addHandler,
    onAddBundle,
    onDeleteFromBundle,
    setBreakpoint,
    removeBreakpoint,
    isTracing,
    isBreakPoint,
    commentCheckMethod,
    inCommunication,
    breakpointType,
    breakpoints,
    debugProcess,
    addBundleControlButton,
    isInvalid,
    onClickInvalidButton,
    ...args
  }) => {
    const dispatch = useDispatch();
    const workflow = useSelector((state) => state.workflow);
    const workspace = useSelector((state) => state.workspace);
    const [isComment, setIsComment] = useState(false);
    const {
      bundle: { bundlingMode },
    } = useContext(WorkflowContext); //번들링 관련

    const nodes = useNodes();
    const edges = useEdges();

    useEffect(() => {
      setIsComment(commentCheckMethod());
    }, [comment]);

    /**
     * 워크플로우(서비스) 목록 호출
     * @param {*} e
     */
    const onOpenWorkflowList = (e) => {
      const callbackFnc = (data) => {
        const body = {
          ...process,
          propertyValue: { ...data },
        };
        WorkflowReduxHelper.updateNodes(dispatch, [body], workflow);
        Popup.close();
      };

      Popup.open(
        <ServicePopup
          callbackFnc={callbackFnc}
          workspace={workspace}
          workflow={workflow}
          processType={"service"}
          processInfo={process.propertyValue}
          nodes={nodes}
          edges={edges}
          compId={id}
        />,
        {
          style: {
            content: {
              width: "55%",
            },
          },
        }
      );
    };

    /**
     * 프로세스 삭제
     * @param {*} e
     */
    const onDeleteProcess = (e) => {
      stopEvent(e);
      WorkflowReduxHelper.deleteProcess(dispatch, process.compId, workflow);
    };

    /**
     * 해당 서비스로 이동
     * @param {*} e
     * @returns
     */
    const onMoveToService = (e) => {
      stopEvent(e);
      if (isBundling) {
        return Message.alert(
          "You cannot move to another service while grouping.",
          Enums.MessageType.WARN
        );
      }

      if (!workflow.serviceInfo.serviceUid)
        return Message.alert(
          "You can use it after saving.",
          Enums.MessageType.WARN
        );
      const moveNext = () => {
        WorkflowService.getService(process.propertyValue, (res) => {
          if (!res.data)
            return Message.alert(
              "The Service cannot be found.",
              Enums.MessageType.ERROR
            );
          const serviceDetail = WorkflowService.setData(res.data);
          WorkflowReduxHelper.moveToNextService(
            dispatch,
            serviceDetail,
            workflow
          );
        });
      };
      WorkflowService.getService(
        { serviceUid: workflow.serviceInfo.serviceUid },
        (res) => {
          const prevService = WorkflowService.setData(res.data);
          //viewport는 다른경우가 많기 때문에 빼고 비교
          const prev = produce(prevService.serviceContent, (draft) => {
            JsonUtils.removeNode(draft, "viewport");
          });

          const next = produce(workflow.output, (draft) => {
            JsonUtils.removeNode(draft, "viewport");
          });

          const prevMemo = prevService.memo;
          const nextMemo = workflow.memo;
          if (
            JSON.stringify(prev) !== JSON.stringify(next) ||
            JSON.stringify(prevMemo) !== JSON.stringify(nextMemo)
          ) {
            /**
             * 저장 후 진행
             */
            const callback = () => {
              const body = {
                ...workflow.serviceInfo,
                serviceContent: workflow.output,
                serviceComment: workflow.serviceComment,
                serviceMemo: workflow.serviceMemo,
                useYn: "Y",
                commitComment: "",
                releaseCommentYn: "N",
                ...workspace,
              };
              WorkflowService.saveService(body, (res) => {
                WorkflowService.localStorageSave(body);
                Popup.close();
                moveNext();
              });
            };
            const showPopup = () => {
              Popup.open(
                <SaveQuestPopup callback={callback} closeCallback={moveNext} />,
                {
                  effect: {
                    ...Popup.ScaleUp,
                    end: {
                      top: "30%",
                      opacity: 1,
                    },
                  },
                  style: { content: { width: "400px", top: "400px" } },
                }
              );
            };

            const autoSaveInfo = LocalStorageService.get(
              Enums.LocalStorageName.WORKFLOW_AUTOSAVE
            );
            if (autoSaveInfo) {
              if (
                autoSaveInfo.userId === User.getId() &&
                autoSaveInfo.autoSave === "Y"
              ) {
                //자동 저장
                callback();
              } else {
                LocalStorageService.remove(
                  Enums.LocalStorageName.WORKFLOW_AUTOSAVE
                );
                showPopup();
              }
            } else {
              showPopup();
            }
          } else {
            moveNext();
          }
        }
      );
    };

    return (
      <>
        {inCommunication && (
          <>
            {isBreakPoint ? (
              <div className={`debug-info ${breakpointType}`}>
                <div>Before</div>
                <div>in Progress</div>
                <div>After</div>
              </div>
            ) : debugProcess.compId === id ? (
              <div className={`debug-info ${breakpointType}`}>
                <div>Before</div>
                <div className={"blink"}>in Progress</div>
                <div>After</div>
              </div>
            ) : (
              <></>
            )}
          </>
        )}
        <div
          className={`workflow-node ${isChild ? `${parentNodeId}_child` : ""} ${
            comment && ` comment`
          } ${bundlingMode && isBundling ? " bundling" : ""}`}
        >
          <div
            className={`workflow-process-node-wrapper ${
              selected ? "selected" : ""
            } 
            ${isTracing ? " traced " : ""} 
            ${debugProcess?.compId === id ? " border-blink " : ""} 
            service-node`}
          >
            {isComment && <div className="node-comment">Comment</div>}

            <div className="header">
              <div className="title">
                {process.propertyValue.serviceUid ? (
                  <span>
                    <MdMiscellaneousServices size={22} />
                  </span>
                ) : (
                  <button className="blink">
                    <MdError size={25} color="tomato" />
                  </button>
                )}

                <span className="name" style={{ maxWidth: "180px" }}>
                  {process.propertyValue.serviceName}
                </span>
              </div>

              {addBundleControlButton(
                <>
                  <div className="control-button"></div>

                  <div>
                    <Tooltip title="Edit">
                      <button
                        style={{ color: "limegreen" }}
                        onClick={onOpenWorkflowList}
                      >
                        <AiOutlineEdit size={20} />
                      </button>
                    </Tooltip>
                    <Tooltip title="Break Point" placement="top">
                      {breakpoints.find(
                        (bp) => bp.compId === process.compId
                      ) ? (
                        <button
                          onClick={(e) =>
                            removeBreakpoint(e, { compId: process.compId })
                          }
                        >
                          <BsFillPinFill size={20} />
                        </button>
                      ) : (
                        <button
                          onClick={(e) =>
                            setBreakpoint(e, {
                              compId: process.compId,
                              type: Enums.WorkflowNodeType.SERVICE,
                              processName: process.propertyValue.processNm,
                              processType: process.processType,
                            })
                          }
                        >
                          <BsPinAngle size={20} />
                        </button>
                      )}
                    </Tooltip>
                    <button onClick={onDeleteProcess}>
                      <AiOutlineClose size={20} />
                    </button>
                  </div>
                </>
              )}
            </div>

            <div
              className="workflow-process-node "
              onDoubleClick={onOpenWorkflowList}
            >
              <div className="service-border">
                <div>
                  <div>[Module Name] :</div>
                  <div>
                    [Service ID] :{" "}
                    <strong>{process.propertyValue.serviceId}</strong>
                  </div>
                </div>
                <div>
                  <Button
                    variant="success"
                    className="service-edit-button"
                    onClick={onMoveToService}
                    size="sm"
                  >
                    {`GO`}
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {addHandler()}
        </div>
      </>
    );
  }
);
