import { ArrayUtils } from "@alpha/com.bizentro.daaf.front.framework";
import { Enums } from "components/builder/BuilderEnum";
import BuilderHeader, {
  HeaderMapDispatchToProps,
  HeaderMapStateToProps,
} from "components/builder/BuilderHeader";
import BuilderToolbarButton from "components/builder/BuilderToolbarButton";
import { AppContext } from "components/common/AppContextProvider";
import Message from "components/common/Message";
import CommonUtils, { ObjectUtils } from "components/common/utils/CommonUtils";
import StringUtils from "components/common/utils/StringUtils";
import User from "components/common/utils/UserUtils";
import produce from "immer";
import { connect } from "react-redux";
import ProgramDesignService from "services/programDesignService/ProgramDesignService";
import {
  setDesign,
  setDesignLayoutColumn,
  setDesignRelatedTables,
  setUploadFile,
} from "../reducer/ProgramDesignAction";
import Popup from "components/common/Popup";
import SaveDesignPopup from "page/popup/SaveDesignPopup";
import FileService from "services/programDesignService/FileService";

class ProgramDesignHeader extends BuilderHeader {
  constructor(props) {
    super(props);
    this.toolbarStyle = props.toolbarStyle || "menu";
    this.state = {
      saveDropdown: false,
      showNewDropdown: false,
    };
    this.saveDesign = this.saveDesign.bind(this);
    this.saveDesignMst = this.saveDesignMst.bind(this);
    this.saveDesignLayoutColumn = this.saveDesignLayoutColumn.bind(this);
  }

  static contextType = AppContext;

  componentDidMount() {
    this.headerInit();
  }

  /**
   * 새 창
   */
  openNewBlank = () => {
    this.props.setDesign({});
    this.props.setDesignLayoutColumn([]);
    this.props.setDesignRelatedTables([]);
    this.props.setUploadFile([]);
    this.setState({ showNewDropdown: false });
  };

  /**
   * 저장
   */
  saveDesign = (data, cb, saveAs) => {
    // program id check
    if (
      ObjectUtils.isEmpty(this.props.technicalDesign.design) ||
      !this.props.technicalDesign.design.programId
    ) {
      Message.alert("Please Enter Program ID", Enums.MessageType.ERROR);

      return;
    }
    //program nm check
    if (
      ArrayUtils.isEmpty(this.props.technicalDesign.design.designMultilang) ||
      !this.props.technicalDesign.design.designMultilang.find(
        (item) => item.multilangCd === "program_nm"
      ) ||
      this.props.technicalDesign.design.designMultilang.find(
        (item) => item.multilangCd === "program_nm"
      ).multilangText === ""
    ) {
      Message.alert("Please insert Program Name", Enums.MessageType.ERROR);
      return;
    }
    if (saveAs === true) {
      this.saveDesignMst(data, cb, saveAs);
    } else {
      this.saveDesignMst();
    }

    this.setState({ saveDropdown: false });
  };

  /**
   * 다른이름으로 저장
   */
  saveAs = () => {
    const options = {
      effect: Popup.ScaleUp, //Effect.SlideFromTop(default)를 Effect.ScaleUp 로 변경
      style: {
        content: {
          width: "30%", //popup의 크기를 50% (default 60%)
        },
      },
    };

    const callbackFnc = (data, cb) => {
      this.saveDesign(data, cb, true);
      Popup.close();
    };
    Popup.open(
      <SaveDesignPopup
        title={"Save Program Design"}
        saveAs={true}
        callbackFnc={(data, cb) => callbackFnc(data, cb)}
        information={{ programNm: "", programDesc: "" }}
        workspace={this.props.workspace}
      />,
      options
    );
  };

  onSaveFile = async (designMstId) => {
    let fileUploadResult;
    // let attachId = "";
    const uploadFile = this.props.technicalDesign.designUploadFile?.filter(
      (file) => !file.fileId
    );
    if (uploadFile?.length > 0) {
      fileUploadResult = await this.uploadFiles(uploadFile, designMstId);
      if (fileUploadResult.isError) return false;
      else {
        this.props.setUploadFile(fileUploadResult.data);

        Message.alert("Saved Successfully.", Enums.MessageType.SUCCESS);
      }
    }
  };

  /**
   * 파일 업로드
   * @param {*} files
   * @returns {Promise}
   */
  uploadFiles = (files, designMstId) => {
    let formData = new FormData();

    formData.append("userId", User.getId());
    formData.append("originAt", "builder");
    formData.append("appEnvId", "");
    formData.append("appId", "");
    formData.append("attachId", "design/" + designMstId);

    files.map((file) => {
      formData.append("files", file);
    });
    return new Promise((resolve, reject) => {
      FileService.upload(
        formData,
        (res) => {
          resolve(res);
        },
        (err) => {
          reject(err);
        }
      );
    });
  };

  /**
   * Design Mst 저장
   */
  saveDesignMst = (data, cb, saveAs) => {
    const userId = User.getId();

    const designRelatedTables = CommonUtils.deepCopy(
      this.props.technicalDesign.designRelatedTables
    );
    designRelatedTables.forEach((item) => {
      item.insertUserId = StringUtils.isEmpty(item.insertUserId)
        ? userId
        : item.insertUserId;
      item.updtUserId = userId;
    });
    if (saveAs === true) {
      let tmpMultilangList = [
        ...this.props.technicalDesign.design.designMultilang,
      ];
      for (var i = 0; i < tmpMultilangList.length; i++) {
        tmpMultilangList.designMstId = "";
      }
    }

    const newDesignMst = produce(this.props.technicalDesign.design, (draft) => {
      if (!ArrayUtils.isEmpty(designRelatedTables)) {
        draft.designRelatedTablesList = designRelatedTables;
      }
      if (saveAs === true) {
        draft.designMstId = null;
        const tmpDesignMultiLang = draft.designMultilang;
        for (var i = 0; i < tmpDesignMultiLang.length; i++) {
          tmpDesignMultiLang[i].designMstId = null;
          tmpDesignMultiLang[i].multilangId = null;
          if (tmpDesignMultiLang[i].multilangCd === "program_nm") {
            tmpDesignMultiLang[i].multilangText = data.programNm;
          } else if (tmpDesignMultiLang[i].multilangCd === "program_desc") {
            tmpDesignMultiLang[i].multilangText = data.description;
          }
        }
      }
    });

    ProgramDesignService.saveDesignMst(newDesignMst, (result) => {
      if (result.isError) {
        Message.alert(
          "An error occurred while saving",
          Enums.MessageType.ERROR
        );
      } else {
        if (result.data) {
          this.props.setDesign(result.data);
          if (saveAs === true) {
            this.saveDesignLayoutColumn(result.data.designMstId, saveAs);
          } else {
            this.onSaveFile(result.data.designMstId);
            this.saveDesignLayoutColumn(result.data.designMstId);
          }
        }
      }
    });
  };

  /**
   * Design Layout Column 저장
   */
  saveDesignLayoutColumn = (designMstId, saveAs) => {
    const userId = User.getId();

    const newDesignLayoutColumn = produce(
      this.props.technicalDesign.designLayoutColumn,
      (draft) => {
        for (var i = 0; i < draft.length; i++) {
          if (typeof draft[i].columnId === "string" || saveAs === true) {
            draft[i].columnId = null;
          }
          draft[i].designMstId = designMstId;
          const designLayoutColumnLang = draft[i].designLayoutColumnLang;

          for (var j = 0; j < designLayoutColumnLang.length; j++) {
            designLayoutColumnLang[j].columnId = draft[i].columnId;
            designLayoutColumnLang[j].insertUserId =
              draft[i].insertUserId !== null ? draft[i].insertUserId : userId;
            designLayoutColumnLang[j].updtUserId = userId;
            if (saveAs === true) {
              designLayoutColumnLang[j].langId = null;
            }
          }
        }
      }
    );

    ProgramDesignService.saveDesignLayoutColumn(
      { designLayoutColumn: newDesignLayoutColumn, designMstId: designMstId },
      (result) => {
        if (result.isError) {
          Message.alert(
            "An error occurred while saving",
            Enums.MessageType.ERROR
          );
        } else {
          if (result.data) {
            this.props.setDesignLayoutColumn(result.data);
            Message.alert("Save completed", Enums.MessageType.SUCCESS);
          }
        }
      }
    );
  };

  renderHeader() {
    const saveDropdownEvent = this.setDropdownEvent("saveDropdown", "edit");
    const NewTemplDropdownEvent = this.setDropdownEvent(
      "showNewDropdown",
      "new"
    );
    return (
      <>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="new"
            onClick={NewTemplDropdownEvent}
            iconOnly={this.state.mini}
            tooltipTitle="New"
          />
          {this.state.showNewDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.openNewBlank}
                  style={{ width: "250px" }}
                >
                  New (Blank)
                </li>
              </ul>
            </div>
          ) : null}
        </li>
        <li>
          <BuilderToolbarButton
            style={this.toolbarStyle}
            buttonId="edit"
            onClick={saveDropdownEvent}
            iconOnly={this.state.mini}
            tooltipTitle={"Edit"}
          />
          {this.state.saveDropdown ? (
            <div
              className="toolbar-dropdown"
              ref={(element) => {
                this.dropdownMenu = element;
              }}
            >
              <ul>
                <li
                  className="dropdown-item"
                  role="button"
                  style={{ width: "250px" }}
                  onClick={this.saveDesign}
                >
                  Save
                </li>
                <li
                  className="dropdown-item"
                  role="button"
                  onClick={this.saveAs}
                >
                  Save as
                </li>
              </ul>
            </div>
          ) : null}
        </li>
      </>
    );
  }
}

export default connect(
  (state) => ({
    eventHandler: state.eventHandler,
    output: state.outputUI.output,
    memo: state.outputUI.memo,
    information: state.outputUI.information,
    activeComponent: state.activedUIComponent.component,
    workspace: state.workspace,
    technicalDesign: state.technicalDesign,
    ...HeaderMapStateToProps(state),
  }),
  (dispatch) => ({
    ...HeaderMapDispatchToProps(dispatch),
    setDesign: (object) => dispatch(setDesign(object)),
    setDesignLayoutColumn: (object) => dispatch(setDesignLayoutColumn(object)),
    setDesignRelatedTables: (object) =>
      dispatch(setDesignRelatedTables(object)),
    setUploadFile: (object) => dispatch(setUploadFile(object)),
  })
)(ProgramDesignHeader);
