import { Checkbox, FormControlLabel, Tooltip } from "@mui/material";
import { Enums } from "components/builder/BuilderEnum";
import UTextarea from "components/common/element/UTextarea";
import Message from "components/common/Message";
import Modal from "components/common/modal/UModal";
import UModalTemplate from "components/common/modal/UModalTemplate";
import Popup from "components/common/Popup";
import ObjectUtils from "components/common/utils/ObjectUtils";
import StringUtils from "components/common/utils/StringUtils";
import produce from "immer";
import { useEffect, useState } from "react";
import { Col, Form, FormGroup, Row } from "react-bootstrap";

function ServiceDeployPopup({ workflow, callbackFnc, workspace, ...props }) {
  const [serviceInfo, setServiceInfo] = useState({
    description: "",
    serviceId: "",
    serviceName: "",
    serviceType: "S",
    releaseCommentYn: "",
    commitComment: "",
    isTransactionIsolated: false,
    isIgnoreException: false,
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!ObjectUtils.isEmpty(workflow.serviceInfo)) {
      setServiceInfo({
        ...workflow.serviceInfo,
        isTransactionIsolated: workflow.output.service.isTransactionIsolated
          ? workflow.output.service.isTransactionIsolated
          : false,
        isIgnoreException: workflow.output.service.isIgnoreException
          ? workflow.output.service.isIgnoreException
          : false,
      });
    }
  }, []);

  const onChangeProperty = (e) => {
    setServiceInfo({
      ...serviceInfo,
      [e.target.id]: e.target.value,
    });
  };

  const onDeployService = () => {
    if (StringUtils.isEmpty(serviceInfo.serviceId)) {
      return Message.alert("Please enter service ID.", Enums.MessageType.INFO);
    }
    if (serviceInfo.serviceId.length > 100) {
      return Message.alert(
        "Please enter the service ID within 100 characters.",
        Enums.MessageType.INFO
      );
    }
    if (StringUtils.isEmpty(serviceInfo.serviceName)) {
      return Message.alert(
        "Please enter service Name.",
        Enums.MessageType.INFO
      );
    }
    if (StringUtils.isEmpty(serviceInfo.serviceType)) {
      return Message.alert(
        "Please enter service type.",
        Enums.MessageType.INFO
      );
    }

    //종료에 연결된 부분이 있는지 확인 후 없으면 리턴
    const endProcess = workflow.output.service.child.process.find(
      (p) =>
        StringUtils.equalsIgnoreCase(
          p.type,
          Enums.WorkflowNodeType.PROCESS_EDGE
        ) &&
        StringUtils.equalsIgnoreCase(
          p.processType,
          Enums.WorkflowProcessType.END_PROCESS
        )
    );
    const isConnectedToEnd = workflow.output.service.child.connector.find(
      (c) => c.processTo === endProcess.compId
    );
    if (!isConnectedToEnd)
      return Message.alert(
        "There is no connection to the End Node. Please check the connector",
        Enums.MessageType.WARN
      );
    if (StringUtils.isEmpty(serviceInfo.commitComment)) {
      return Message.alert(
        "Please enter deploy comment.",
        Enums.MessageType.INFO
      );
    }

    //아웃풋 내의 id, name, type 재조정
    const newOutput = produce(workflow.output, (draft) => {
      draft.service.serviceId = serviceInfo.serviceId.trim();
      draft.service.serviceName = serviceInfo.serviceName.trim();
      draft.service.serviceType = serviceInfo.serviceType;
      draft.service.isTransactionIsolated = serviceInfo.isTransactionIsolated;
      draft.service.isIgnoreException = serviceInfo.isIgnoreException;
    });
    const body = {
      ...serviceInfo,
      serviceContent: newOutput,
      serviceComment: workflow.serviceComment,
      serviceMemo: workflow.serviceMemo,
      useYn: "Y",
      deployDate: new Date(),
      ...workspace,
    };

    setIsLoading(true);
    if (callbackFnc) {
      callbackFnc(body, () => {
        setIsLoading(false);
        Popup.close();
      });
    }
  };

  const onChangeReleaseCommentYn = (e) => {
    let value = e.target.checked;
    if (value) {
      setServiceInfo({
        ...serviceInfo,
        releaseCommentYn: "Y",
      });
    } else {
      setServiceInfo({
        ...serviceInfo,
        releaseCommentYn: "N",
      });
    }
  };

  return (
    <Modal>
      <Modal.Header title="Service Save" />
      <Modal.Body>
        <UModalTemplate>
          <Row>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Application</Form.Label>
              <Form.Control disabled defaultValue={workspace.appNm} />
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Module</Form.Label>
              <Form.Control disabled defaultValue={workspace.moduleNm} />
            </Form.Group>
            <Form.Group className="mb-3" as={Col}>
              <Form.Label>Version</Form.Label>
              <Form.Control disabled defaultValue={workspace.version} />
            </Form.Group>
          </Row>
          <Row>
            <Form.Group as={Col} className="mb-3">
              <Form.Label>Tenant ID</Form.Label>
              <Form.Control disabled defaultValue={workspace.tenantId} />
            </Form.Group>

            <Form.Group as={Col}>
              <Form.Label>Company Code</Form.Label>
              <Form.Control disabled defaultValue={workspace.coCd} />
            </Form.Group>
          </Row>
          <FormGroup>
            <Form.Label className="required">Service ID</Form.Label>
            <Form.Control
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceId"}
              readOnly={!StringUtils.isEmpty(serviceInfo.serviceUid)}
              value={serviceInfo.serviceId}
              placeholder="Input Service ID"
              maxLength={30}
            />
            <Form.Label className="required">Service Name</Form.Label>
            <Form.Control
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceName"}
              value={serviceInfo.serviceName}
              placeholder="Input Service name"
              maxLength={100}
            />
            <Form.Label className="required">Service Type</Form.Label>
            <Form.Select
              className="mb-3"
              onChange={onChangeProperty}
              id={"serviceType"}
              value={serviceInfo.serviceType}
            >
              <option value="">Choose</option>
              <option value="S">Business Flow</option>
              <option value="G">Global Variable Definition Flow</option>
            </Form.Select>

            <Form.Label className="required">Transaction Mode</Form.Label>
            <span className="mb-3">
              <Form.Check
                type="radio"
                name="isTransactionIsolated"
                label={
                  "Shared Transaction (Commit deferred until overall process completion)"
                }
                value={false}
                checked={serviceInfo.isTransactionIsolated === false}
                onChange={(e) => {
                  setServiceInfo({
                    ...serviceInfo,
                    isTransactionIsolated: false,
                  });
                }}
              />
              <Form.Check
                type="radio"
                name="isTransactionIsolated"
                label={
                  "Isolated Transaction (Auto-commit immediately after service execution)"
                }
                value={true}
                checked={serviceInfo.isTransactionIsolated === true}
                onChange={(e) => {
                  setServiceInfo({
                    ...serviceInfo,
                    isTransactionIsolated: true,
                  });
                }}
              />
            </span>

            {serviceInfo.isTransactionIsolated && (
              <>
                <Form.Label className="required">Exception Handling</Form.Label>
                <span className="mb-3">
                  <Form.Check
                    type="radio"
                    name="isIgnoreException"
                    label={
                      "On exception, rollback previous transaction and stop the service."
                    }
                    value={false}
                    checked={serviceInfo.isIgnoreException === false}
                    onChange={(e) => {
                      setServiceInfo({
                        ...serviceInfo,
                        isIgnoreException: false,
                      });
                    }}
                  />
                  <Form.Check
                    type="radio"
                    name="isIgnoreException"
                    label={"Ignore exceptions and continue service execution"}
                    value={true}
                    checked={serviceInfo.isIgnoreException === true}
                    onChange={(e) => {
                      setServiceInfo({
                        ...serviceInfo,
                        isIgnoreException: true,
                      });
                    }}
                  />
                </span>
              </>
            )}

            <Form.Label>Service Description</Form.Label>
            <UTextarea
              popTitle="Service Description"
              textareaId="description"
              onChange={onChangeProperty}
              defaultValue={serviceInfo.description}
              codeBox={false}
              rows={10}
            />
            <Form.Label className="required mt-3">
              Service Deploy Comment
            </Form.Label>
            <Form.Control
              className="mb-3"
              onChange={onChangeProperty}
              id={"commitComment"}
              value={serviceInfo.commitComment || ""}
              placeholder="Please enter Service deploy Comment."
            />
            <Form.Group
              className="mb-3"
              style={{ display: "flex", justifyContent: "flex-end" }}
            >
              <Tooltip
                title="When deploying a new version, you can choose whether to include 
the revision details from the previous version."
                placement="left"
              >
                <FormControlLabel
                  label={"Include deployment comments in the revision"}
                  control={
                    <Checkbox
                      checked={serviceInfo.releaseCommentYn === "Y"}
                      onChange={onChangeReleaseCommentYn}
                    />
                  }
                />
              </Tooltip>
            </Form.Group>
          </FormGroup>
        </UModalTemplate>
      </Modal.Body>
      <Modal.Footer>
        <Modal.Footer.ProgressButton
          onClick={onDeployService}
          doing={isLoading}
          doingText="Progressing"
        >
          Deploy
        </Modal.Footer.ProgressButton>
      </Modal.Footer>
    </Modal>
  );
}

export default ServiceDeployPopup;
